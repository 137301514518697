import {useEffect, useState, useContext} from "react";
import dayjs from 'dayjs';
import apiClient from "../services/api";
import {LoadingContext} from "../contexts/LoadingContext";
import {AppointmentDetailsContext} from "../contexts/AppointmentDetailsContext";

import {cleanNotifications, showNotification, updateNotification} from '@mantine/notifications';
import {CheckIcon, FaceFrownIcon} from '@heroicons/react/24/outline'
import {TbStethoscope} from 'react-icons/tb';
import {BsCalendar2} from 'react-icons/bs';
import 'dayjs/locale/ro';

import {Select, Grid, Container} from '@mantine/core';
import {DatePicker} from '@mantine/dates';
import {useForm} from "@mantine/form";
import styled from "styled-components";
import SearchResults from "./SearchResults";
import PatientDetails from "./PatientDetails";

function SearchWrapper() {
    const [departments, setDepartments] = useState([]);
    const [slotsData, setSlotsData] = useState([]);
    const [allowedDates, setAllowedDates] = useState([]);
    const {appointmentData, setAppointmentData} = useContext(AppointmentDetailsContext);

    const {setShowLoading} = useContext(LoadingContext);
    const form = useForm({
        initialValues: {
            department: null,
            date: ''
        },
        validate: {
            department: (value) => (!value ? 'Please set department' : null),
            date: (value) => (!value ? 'Please set date' : null)
        },
    });
    useEffect(() => {
        getDepartments();

        return () => {
            console.log('resetting slots & appointment data');
            setSlotsData([]);
            setAppointmentData({});
            setShowLoading(false)
            cleanNotifications({});
        }
    }, [])



    useEffect(() => {
        if (appointmentData.department && appointmentData.date) {
            getSlots({...appointmentData})
        } else {
            setAppointmentData({...appointmentData, start: null, end: null});
            if (appointmentData.department && !appointmentData.date) {
                setAllowedDates([]);
                getDates(appointmentData.department);
            }
            setSlotsData([]);
        }
    }, [appointmentData.date, appointmentData.department])

    const handleError = (errors) => {
        console.log(errors);
        if (errors.department) {
            showNotification({message: 'Va rugam sa alegeti o specialitate', color: 'red'});
        } else if (errors.date) {
            showNotification({message: 'Va rugam sa alegeti data', color: 'red'});
        }
    };

    // const handleSubmit = (values) => {
    //     setAppointmentData({...appointmentData,...values});
    //     getSlots({...values, formattedDate});
    // };

    const getDepartments = async () => {
        setShowLoading(true);
        showNotification({
            id: 'load-departments',
            loading: true,
            title: 'Incarc lista de specialitati',
            message: 'Va rugam sa asteptati',
            autoClose: false,
            disallowClose: true,
        });
        const response = await apiClient.get('api/departments');


        setDepartments(response.data.map((department) => {
            return {
                value: department.id.toString(),
                label: department.name
            }
        }));

        updateNotification({
            id: 'load-departments',
            color: 'teal',
            title: 'Specialitatile au fost incarcate',
            autoClose: 2000,
        });

        setShowLoading(false)
    }

    const getDates = async (department) => {
        setShowLoading(true);
        form.setFieldValue('date', null);
        setSlotsData([])
        showNotification({
            id: 'load-calendar',
            loading: true,
            title: 'Incarc calendarul',
            message: 'Va rugam sa asteptati',
            autoClose: false,
            disallowClose: true,
        });
        //
        const response = await apiClient.get('api/availabilities/dates/' + department);

        if (response.data.length) {
            setAllowedDates(response.data);

            updateNotification({
                id: 'load-calendar',
                color: 'teal',
                title: 'Calendarul a fost incarcat, alegeti o data',
                autoClose: 2000,
            });

        } else {
            updateNotification({
                id: 'load-calendar',
                color: 'red',
                title: 'Nu am gasit date disponibile pentru specialitatea selectata',
                autoClose: 2000,
            });
        }

        setShowLoading(false)

    }

    const getSlots = async ({department, date}) => {
        setShowLoading(true);
        showNotification({
            id: 'load-slots',
            loading: true,
            title: 'Aduc locurile libere',
            message: 'Va rugam sa asteptati',
            autoClose: false,
            disallowClose: true,
        });

        try {
            const response = await apiClient.get('api/availabilities/slots/' + department + '/' + date);
            setSlotsData(response.data);

            updateNotification({
                id: 'load-slots',
                color: 'teal',
                title: 'Locurile libere au fost incarcate',
                autoClose: 2000,
            });

        } catch (err) {
            updateNotification({
                id: 'load-slots',
                color: 'red',
                title: 'A intervenit o eroare',
                autoClose: 2000,
            });

            setSlotsData([]);
        }



        setShowLoading(false)
    }

    return (
        <Container size='lg' className='p-top-40 mh-100'>
            <Card>
                <form>
                    <div className='medisharp-search-wrapper'>
                        <Grid grow>
                            <Grid.Col span={4}>
                                <Select
                                    withAsterisk
                                    label="Specialitate"
                                    placeholder="Alegeti specialitatea"
                                    data={departments}
                                    icon={<TbStethoscope className='medisharp-specialty-icon'/>}
                                    className='medisharp-specialty-wrapper medisharp-input-wrapper'
                                    {...form.getInputProps('department')} onChange={(departmentId) => {
                                    form.setFieldValue('department', departmentId);
                                    setAppointmentData({...appointmentData, department: departmentId, date: null});
                                }}
                                />
                            </Grid.Col>


                            <Grid.Col span={4}>
                                <DatePicker
                                    withAsterisk
                                    clearable={false}
                                    locale='ro'
                                    inputFormat="D MMMM YYYY"
                                    placeholder="Alegeti o data"
                                    className='medisharp-date-wrapper medisharp-input-wrapper'
                                    label="Data programării"
                                    icon={<BsCalendar2 className='medisharp-date-icon'/>}
                                    minDate={dayjs(new Date()).add(1, 'days').toDate()}
                                    excludeDate={(date) => !allowedDates.includes(dayjs(date).format('YYYY-MM-DD'))}
                                    {...form.getInputProps('date')} onChange={(date) => {
                                    form.setFieldValue('date', dayjs(date).format('YYYY-MM-DD'));
                                    setAppointmentData({
                                        ...appointmentData,
                                        date: dayjs(date).format('YYYY-MM-DD'),
                                        start: null,
                                        end: null
                                    })
                                }}
                                />
                            </Grid.Col>

                        </Grid>
                    </div>

                </form>
            </Card>

            {
                appointmentData.start && appointmentData.end ?
                    <PatientDetails {...slotsData}></PatientDetails>
                    : slotsData && slotsData?.doctors?.length ?
                        <SearchResults {...slotsData}/> : ''
            }

        </Container>

    )
}

const Card = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 40px 30px;
  box-shadow: 0px 13px 40px -20px rgb(7 29 43 / 29%) !important;
  margin-bottom: 30px;
`

export default SearchWrapper;
