import Hero from "../components/Hero";

function Home() {
      return (
        <>
            <Hero/>
        </>

    )
}

export default Home;