import Home from "./pages/Home";
import './App.css';
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import {LoadingOverlay} from "@mantine/core";
import {LoadingContext} from "./contexts/LoadingContext";
import {NotificationsProvider} from '@mantine/notifications';
import {useState} from "react";
import {AppointmentDetailsContext} from "./contexts/AppointmentDetailsContext";
import ConfirmAppointment from "./pages/ConfirmAppointment";
import {BrowserRouter, Redirect, Route, Routes} from "react-router-dom";
import Appointment from "./components/Appointment";
import AppointmentConfirmed from "./pages/AppointmentConfirmed";
import CancelAppointment from "./pages/CancelAppointment";
import AppointmentCanceled from "./pages/AppointmentCanceled";
import TopNotification from "./components/TopNotification";
import ContactRedirect from "./components/ContactRedirect";


const links = [
    { "link": "/", "label": "Acasa" },
    { "link": "/cancel-appointment", "label": "Anulare programare" },
    { "link": "/contact", "label": "Contact" }
]


const App = () => {

    const [showLoading, setShowLoading] = useState(false);
    const [appointmentData, setAppointmentData] = useState({});
    return (
        <div className="medisharp-app">
            <NotificationsProvider>
                <LoadingContext.Provider value={{showLoading, setShowLoading}}>
                    <AppointmentDetailsContext.Provider value={{appointmentData, setAppointmentData}}>
                        <BrowserRouter>
                            <TopNotification />
                            <Nav links={links}/>
                            <Routes>
                                <Route path="/" element={<Home />}/>
                                <Route path="/appointment" element={<Appointment />}/>
                                <Route path="/confirm-appointment/:uuid" element={<ConfirmAppointment/>}/>
                                <Route path="/cancel-appointment" element={<CancelAppointment/>}/>
                                <Route path="/appointment-confirmed" element={<AppointmentConfirmed />}/>
                                <Route path="/appointment-canceled" element={<AppointmentCanceled />}/>
                                <Route
                                    path="/contact"
                                    element={ <ContactRedirect /> }
                                />
                            </Routes>
                            <Footer links={links}/>
                        </BrowserRouter>
                    </AppointmentDetailsContext.Provider>
                    <LoadingOverlay visible={showLoading} overlayBlur={3}/>
                </LoadingContext.Provider>
            </NotificationsProvider>
        </div>
    )

}

export default App;
