import {Link, NavLink} from "react-router-dom";
import {Container, createStyles, Group} from "@mantine/core";
import logo from "../logo-grey.png";


const useStyles = createStyles((theme) => ({
    wrapper: {
      backgroundColor: "#e94947",
    },

    inner: {
        paddingTop: theme.spacing.xs,
        paddingBottom: theme.spacing.xs,
        textAlign: "center",
        color: '#fff',
        fontWeight: 300,
        fontSize: "13px",
        lineHeight: "20px",

        [theme.fn.smallerThan('xs')]: {
            flexDirection: 'column',
        },
    },

}));

export default function TopNotification() {
    const { classes } = useStyles();

    return (
        <div className={classes.wrapper}>
            <Container size='lg' className={classes.inner}>
                Sistemul de notificări prin SMS este inactiv temporar, va rugăm să folosiți codurile de confirmare primite pe adresa de email.
            </Container>
        </div>
    );
}