import React, { useEffect } from 'react';

function ContactRedirect() {
    useEffect(() => {
        // Redirect to an external URL
        window.location.href = 'https://spitalulsalonta.ro/contact';
    }, []);

    return (
        <div>
            {/* This component won't actually render anything since the redirect will happen immediately */}
        </div>
    );
}

export default ContactRedirect;
